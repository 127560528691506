.box-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 10px;
  gap: 10em;
  transition: gap 0.75s 1s ease-in-out;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.box-grid-loaded {
  gap: 6em;
}
