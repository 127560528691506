.box {
  /* background-color: rgba(255, 255, 255, 0.97); */
  background-color: transparent !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5em;
  position: relative;
}

.box a {
  /* border: 3px solid transparent; */
  background-color: inherit;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  /* position: absolute;
  left: 0;
  top: 0; */
  /* width: 100%;
  height: 100%; */
  transition: all 0.35s ease-in-out;
}

.box a:focus,
.box a:hover {
  text-decoration: underline;
  /* background-color: rgba(183, 202, 212, 0.97); */
  /* color: black; */
  transition: all 0.35s ease-in-out;
  /* border: 3px solid rgba(250, 250, 250, 1); */
}

.box:nth-child(n) {
  /* background-color: rgba(193, 202, 212, 0.97); */
}

.box:nth-child(2),
.box:nth-child(3) {
  /* background-color: rgba(250, 250, 250, 0.97); */
}

.box-text {
  font-size: 1.25rem;
}
.box-one {
  /* grid-column: 1; */
}
.box-two-end {
  grid-column: 2 / 4;
}
.box-two-start {
  grid-column: 1 / 3;
}
